var exports = {};
var isff = "undefined" != typeof navigator && 0 < navigator.userAgent.toLowerCase().indexOf("firefox");

function addEvent(e, n, t, o) {
  e.addEventListener ? e.addEventListener(n, t, o) : e.attachEvent && e.attachEvent("on".concat(n), function () {
    t(window.event);
  });
}

function getMods(e, n) {
  for (var t = n.slice(0, n.length - 1), o = 0; o < t.length; o++) t[o] = e[t[o].toLowerCase()];

  return t;
}

function getKeys(e) {
  for (var n = (e = (e = "string" != typeof e ? "" : e).replace(/\s/g, "")).split(","), t = n.lastIndexOf(""); 0 <= t;) n[t - 1] += ",", n.splice(t, 1), t = n.lastIndexOf("");

  return n;
}

function compareArray(e, n) {
  for (var t = e.length < n.length ? n : e, o = e.length < n.length ? e : n, r = !0, i = 0; i < t.length; i++) ~o.indexOf(t[i]) || (r = !1);

  return r;
}

for (var _keyMap = {
  backspace: 8,
  "\u232B": 8,
  tab: 9,
  clear: 12,
  enter: 13,
  "\u21A9": 13,
  return: 13,
  esc: 27,
  escape: 27,
  space: 32,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  del: 46,
  delete: 46,
  ins: 45,
  insert: 45,
  home: 36,
  end: 35,
  pageup: 33,
  pagedown: 34,
  capslock: 20,
  num_0: 96,
  num_1: 97,
  num_2: 98,
  num_3: 99,
  num_4: 100,
  num_5: 101,
  num_6: 102,
  num_7: 103,
  num_8: 104,
  num_9: 105,
  num_multiply: 106,
  num_add: 107,
  num_enter: 108,
  num_subtract: 109,
  num_decimal: 110,
  num_divide: 111,
  "\u21EA": 20,
  ",": 188,
  ".": 190,
  "/": 191,
  "`": 192,
  "-": isff ? 173 : 189,
  "=": isff ? 61 : 187,
  ";": isff ? 59 : 186,
  "'": 222,
  "[": 219,
  "]": 221,
  "\\": 220
}, _modifier = {
  "\u21E7": 16,
  shift: 16,
  "\u2325": 18,
  alt: 18,
  option: 18,
  "\u2303": 17,
  ctrl: 17,
  control: 17,
  "\u2318": 91,
  cmd: 91,
  command: 91
}, modifierMap = {
  16: "shiftKey",
  18: "altKey",
  17: "ctrlKey",
  91: "metaKey",
  shiftKey: 16,
  ctrlKey: 17,
  altKey: 18,
  metaKey: 91
}, _mods = {
  16: !1,
  18: !1,
  17: !1,
  91: !1
}, _handlers = {}, k = 1; k < 20; k++) _keyMap["f".concat(k)] = 111 + k;

var _downKeys = [],
    winListendFocus = !1,
    _scope = "all",
    elementHasBindEvent = [],
    code = function (e) {
  return _keyMap[e.toLowerCase()] || _modifier[e.toLowerCase()] || e.toUpperCase().charCodeAt(0);
},
    getKey = function (n) {
  return Object.keys(_keyMap).find(function (e) {
    return _keyMap[e] === n;
  });
},
    getModifier = function (n) {
  return Object.keys(_modifier).find(function (e) {
    return _modifier[e] === n;
  });
};

function setScope(e) {
  _scope = e || "all";
}

function getScope() {
  return _scope || "all";
}

function getPressedKeyCodes() {
  return _downKeys.slice(0);
}

function getPressedKeyString() {
  return _downKeys.map(function (e) {
    return getKey(e) || getModifier(e) || String.fromCharCode(e);
  });
}

function filter(e) {
  var e = e.target || e.srcElement,
      n = e.tagName;
  return !e.isContentEditable && ("INPUT" !== n && "TEXTAREA" !== n && "SELECT" !== n || e.readOnly) ? !0 : !1;
}

function isPressed(e) {
  return "string" == typeof e && (e = code(e)), !!~_downKeys.indexOf(e);
}

function deleteScope(e, n) {
  var t, o, r;

  for (r in e = e || getScope(), _handlers) if (Object.prototype.hasOwnProperty.call(_handlers, r)) for (t = _handlers[r], o = 0; o < t.length;) t[o].scope === e ? t.splice(o, 1) : o++;

  getScope() === e && setScope(n || "all");
}

function clearModifier(e) {
  var n = e.keyCode || e.which || e.charCode,
      t = _downKeys.indexOf(n);

  if (t < 0 || _downKeys.splice(t, 1), e.key && "meta" == e.key.toLowerCase() && _downKeys.splice(0, _downKeys.length), (n = 93 !== n && 224 !== n ? n : 91) in _mods) for (var o in _mods[n] = !1, _modifier) _modifier[o] === n && (hotkeys[o] = !1);
}

function unbind(e) {
  if (void 0 === e) Object.keys(_handlers).forEach(function (e) {
    return delete _handlers[e];
  });else if (Array.isArray(e)) e.forEach(function (e) {
    e.key && eachUnbind(e);
  });else if ("object" == typeof e) e.key && eachUnbind(e);else if ("string" == typeof e) {
    for (var n = arguments.length, t = Array(1 < n ? n - 1 : 0), o = 1; o < n; o++) t[o - 1] = arguments[o];

    var r = t[0],
        i = t[1];
    "function" == typeof r && (i = r, r = ""), eachUnbind({
      key: e,
      scope: r,
      method: i,
      splitKey: "+"
    });
  }
}

var eachUnbind = function (e) {
  var r = e.scope,
      i = e.method,
      n = e.splitKey,
      s = void 0 === n ? "+" : n;
  getKeys(e.key).forEach(function (e) {
    var n,
        e = e.split(s),
        t = e.length,
        o = e[t - 1],
        o = "*" === o ? "*" : code(o);
    _handlers[o] && (r = r || getScope(), n = 1 < t ? getMods(_modifier, e) : [], _handlers[o] = _handlers[o].filter(function (e) {
      return !((!i || e.method === i) && e.scope === r && compareArray(e.mods, n));
    }));
  });
};

function eventHandler(e, n, t, o) {
  var r;

  if (n.element === o && (n.scope === t || "all" === n.scope)) {
    for (var i in r = 0 < n.mods.length, _mods) Object.prototype.hasOwnProperty.call(_mods, i) && (!_mods[i] && ~n.mods.indexOf(+i) || _mods[i] && !~n.mods.indexOf(+i)) && (r = !1);

    (0 !== n.mods.length || _mods[16] || _mods[18] || _mods[17] || _mods[91]) && !r && "*" !== n.shortcut || !1 === n.method(e, n) && (e.preventDefault ? e.preventDefault() : e.returnValue = !1, e.stopPropagation && e.stopPropagation(), e.cancelBubble && (e.cancelBubble = !0));
  }
}

function dispatch(t, e) {
  var n = _handlers["*"],
      o = t.keyCode || t.which || t.charCode;

  if (hotkeys.filter.call(this, t)) {
    if (~_downKeys.indexOf(o = 93 !== o && 224 !== o ? o : 91) || 229 === o || _downKeys.push(o), ["ctrlKey", "altKey", "shiftKey", "metaKey"].forEach(function (e) {
      var n = modifierMap[e];
      t[e] && !~_downKeys.indexOf(n) ? _downKeys.push(n) : !t[e] && ~_downKeys.indexOf(n) ? _downKeys.splice(_downKeys.indexOf(n), 1) : "metaKey" !== e || !t[e] || 3 !== _downKeys.length || t.ctrlKey || t.shiftKey || t.altKey || (_downKeys = _downKeys.slice(_downKeys.indexOf(n)));
    }), o in _mods) {
      for (var r in _mods[o] = !0, _modifier) _modifier[r] === o && (hotkeys[r] = !0);

      if (!n) return;
    }

    for (var i in _mods) Object.prototype.hasOwnProperty.call(_mods, i) && (_mods[i] = t[modifierMap[i]]);

    t.getModifierState && (!t.altKey || t.ctrlKey) && t.getModifierState("AltGraph") && (~_downKeys.indexOf(17) || _downKeys.push(17), ~_downKeys.indexOf(18) || _downKeys.push(18), _mods[17] = !0, _mods[18] = !0);
    var s = getScope();
    if (n) for (var d = 0; d < n.length; d++) n[d].scope === s && ("keydown" === t.type && n[d].keydown || "keyup" === t.type && n[d].keyup) && eventHandler(t, n[d], s, e);
    if (o in _handlers) for (var a = 0; a < _handlers[o].length; a++) if (("keydown" === t.type && _handlers[o][a].keydown || "keyup" === t.type && _handlers[o][a].keyup) && _handlers[o][a].key) {
      for (var c = _handlers[o][a], f = c.key.split(c.splitKey), l = [], y = 0; y < f.length; y++) l.push(code(f[y]));

      l.sort().join("") === _downKeys.sort().join("") && eventHandler(t, c, s, e);
    }
  }
}

function isElementBind(e) {
  return !!~elementHasBindEvent.indexOf(e);
}

function hotkeys(e, n, t) {
  _downKeys = [];
  var o = getKeys(e),
      r = [],
      i = "all",
      s = document,
      d = 0,
      a = !1,
      c = !0,
      f = "+",
      l = !1;

  for (void 0 === t && "function" == typeof n && (t = n), "[object Object]" === Object.prototype.toString.call(n) && (n.scope && (i = n.scope), n.element && (s = n.element), n.keyup && (a = n.keyup), void 0 !== n.keydown && (c = n.keydown), void 0 !== n.capture && (l = n.capture), "string" == typeof n.splitKey && (f = n.splitKey)), "string" == typeof n && (i = n); d < o.length; d++) r = [], 1 < (e = o[d].split(f)).length && (r = getMods(_modifier, e)), (e = "*" === (e = e[e.length - 1]) ? "*" : code(e)) in _handlers || (_handlers[e] = []), _handlers[e].push({
    keyup: a,
    keydown: c,
    scope: i,
    mods: r,
    shortcut: o[d],
    method: t,
    key: o[d],
    splitKey: f,
    element: s
  });

  void 0 !== s && !isElementBind(s) && window && (elementHasBindEvent.push(s), addEvent(s, "keydown", function (e) {
    dispatch(e, s);
  }, l), winListendFocus || (winListendFocus = !0, addEvent(window, "focus", function () {
    _downKeys = [];
  }, l)), addEvent(s, "keyup", function (e) {
    dispatch(e, s), clearModifier(e);
  }, l));
}

function trigger(n) {
  var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : "all";
  Object.keys(_handlers).forEach(function (e) {
    e = _handlers[e].find(function (e) {
      return e.scope === t && e.shortcut === n;
    });
    e && e.method && e.method();
  });
}

var a,
    _hotkeys,
    _api = {
  getPressedKeyString: getPressedKeyString,
  setScope: setScope,
  getScope: getScope,
  deleteScope: deleteScope,
  getPressedKeyCodes: getPressedKeyCodes,
  isPressed: isPressed,
  filter: filter,
  trigger: trigger,
  unbind: unbind,
  keyMap: _keyMap,
  modifier: _modifier,
  modifierMap: modifierMap
};

for (a in _api) Object.prototype.hasOwnProperty.call(_api, a) && (hotkeys[a] = _api[a]);

"undefined" != typeof window && (_hotkeys = window.hotkeys, hotkeys.noConflict = function (e) {
  return e && window.hotkeys === hotkeys && (window.hotkeys = _hotkeys), hotkeys;
}, window.hotkeys = hotkeys), exports = hotkeys;
export default exports;